<template>
  <div class="pd10">
    <serial-port :port="configInfo.testingPort" :timeOut="configInfo.testingTimeOut"></serial-port>
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">版本上传配置</span>
        <el-button class="right" type="text" @click="onUploadSubmit('uploadForm')">修改</el-button>
      </div>
      <el-form ref="uploadForm" :rules="uploadRules" :model="uploadForm" label-width="80px">
        <el-form-item label="上传地址" prop="path" class="custom-form-item">
          <el-input v-model="uploadForm.path" placeholder="上传地址"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">版本下载配置</span>
        <el-button class="right" type="text" @click="onDownSubmit('downForm')">修改</el-button>
      </div>
      <el-form ref="downForm" :rules="downRules" :model="downForm" label-width="80px">
        <el-form-item label="下载路径" prop="prefix" class="custom-form-item">
          <el-input v-model="downForm.prefix" placeholder="下载路径"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">默认仓型配置</span>
        <el-button class="right" type="text" @click="onCangSubmit('cangForm')">修改</el-button>
      </div>
      <el-form ref="cangForm" :rules="cangRules" :model="cangForm" label-width="80px">
        <el-form-item label="默认仓型" prop="cangType" class="custom-form-item">
          <el-select v-model="cangForm.cangType" placeholder="请选择" style="width: 100%;">
            <el-option v-for="(item,index) in cangTypeList" :key="index" :value="item.cangType" :label="item.cangName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="修改仓型" prop="cangName" class="custom-form-item">
          <el-input v-model="cangForm.cangName" placeholder="修改仓型"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">激活码生成</span>
        <el-button class="right" type="text" @click="onCodeSubmit('codeForm')">生成</el-button>
      </div>
      <el-form ref="codeForm" :rules="codeRules" :model="codeForm" label-width="80px">
        <el-form-item label="设备号" prop="deviceNumber" class="custom-form-item">
          <el-input v-model="codeForm.deviceNumber" placeholder="客户机设备号"></el-input>
        </el-form-item>
        <el-form-item label="天数" prop="day" class="custom-form-item">
          <el-input-number v-model="codeForm.day" style="width: 100%;" controls-position="right" :min="1">
          </el-input-number>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  export default {
    components: {
      "serial-port": () => import('./components/SerialPort')
    },
    data() {
      return {
        configInfo:{},
        cangForm: {
          cangType: '',
          cangName: ''
        },
        cangTypeList: [],
        cangRules: {
          cangType: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          cangName: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
        },
        codeForm: {
          deviceNumber: "",
          day: ""
        },
        codeRules: {
          deviceNumber: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          day: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
        },
        downForm: {
          prefix: ''
        },
        downRules: {
          prefix: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
        },
        uploadForm: {
          path: ''
        },
        uploadRules: {
          path: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
        },
        // downPrefix: "",
        // uploadEditionPrefix: ""
      }
    },
    mounted() {
      this.cfCangTypeList()
    },
    provide() {
      return {
        search: this.cfCangTypeList
      }
    },
    methods: {
      //仓型获取
      cfCangTypeList() {
        this.$axios.Post(this.$api.configureAll, {}).then((res) => {
          this.cangTypeList = res.data.cangConfig
          this.downForm.prefix = res.data.downPrefix
          this.uploadForm.path = res.data.uploadEditionPrefix
          this.configInfo=res.data
        })
      },
      //修改提交
      onCangSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = JSON.parse(JSON.stringify(this.cangTypeList))
            let current = params.findIndex(item => item.cangType === this.cangForm.cangType)
            if (current !== -1) params[current].cangName = this.cangForm.cangName

            this.$axios.Post(this.$api.configureCangType, {
              cangConfig: JSON.stringify(params)
            }).then(() => {
              this.$message({
                type: 'success',
                message: "修改成功"
              });
              this.cfCangTypeList();
            }).catch((err) => {
              if (err.errMsg) {
                this.$message({
                  type: 'error',
                  message: err.errMsg
                });
              }
            })
          } else {
            return false;
          }
        });
      },
      //激活码生成
      onCodeSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.Post(this.$api.configureActivation, this.codeForm).then((res) => {
              this.$confirm('生成成功，您的激活码为：' + res.data, '提示', {
                confirmButtonText: '确定',
                type: 'success',
                showCancelButton: false
              }).then(() => {}).catch(() => {});
            }).catch((err) => {
              if (err.errMsg) {
                this.$message({
                  type: 'error',
                  message: err.errMsg
                });
              }
            })
          } else {
            return false;
          }
        });
      },
      //下载前缀改
      onDownSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.Post(this.$api.configureDownPrefix, this.downForm).then(() => {
              this.$message({
                type: 'success',
                message: "修改成功"
              });
              this.cfCangTypeList();
            }).catch((err) => {
              if (err.errMsg) {
                this.$message({
                  type: 'error',
                  message: err.errMsg
                });
              }
            })
          } else {
            return false;
          }
        });
      },
      //上传底子修改
      onUploadSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.Post(this.$api.configurePath, this.uploadForm).then(() => {
              this.$message({
                type: 'success',
                message: "修改成功"
              });
              this.cfCangTypeList();
            }).catch((err) => {
              if (err.errMsg) {
                this.$message({
                  type: 'error',
                  message: err.errMsg
                });
              }
            })
          } else {
            return false;
          }
        });
      }
    }
  }
</script>

<style scoped lang="less">
  .custom-card+.custom-card {
    margin-top: 10px;
  }
</style>
